<template>
    <div>
      <div class="d-flex justify-content-center align-items-center" v-if="showPreloader || $store.state.refresh.fetchingNewData || $store.state.refresh.waiting_filter" style="height: 90vh;">
        <b-spinner variant="primary" label="Text Centered" />
      </div>
      <div v-else>
       <table-organization :options="organization" @editForm="editForm"></table-organization>
      </div>
      <modal-organization :typeOrganiza="typeOrganiza" @refresh="refresh" ></modal-organization>
      <modal-organization-edit :id="id" @refresh="refresh"></modal-organization-edit>
      <filter-modalVue :fields="fields" @sendToParent="sendToParent"></filter-modalVue>
    </div>
</template>
  <script>
  import ModalOrganization from '@/views/component/Modal/ModalOrganization/ModalOrganization.vue'
  import ModalOrganizationEdit from '@/views/component/Modal/ModalOrganization/ModalOrganizationEdit.vue'
  import tableOrganization from '@/views/component/Table/tableOrganization.vue'
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
  export default {
    components: {
      ModalOrganization,
      ModalOrganizationEdit,
      tableOrganization,
    },
    data() {
      return {
        organization: [],
        typeOrganiza: [],
        showPreloader: false,
        id: undefined,
        fields: [
          { key: 'id', label: 'ID' },
          { key: 'name', label: 'Название' },
          { key: 'org_type', label: 'Тип организция' },
          { key: 'is_active', label: 'Статус' },
          { key: 'date_time', label: 'Создан' },
        ],
        selectedBodyType: null,
      }
    },
    mounted(){
      this.$store.commit('pageData/setdataCount', null)
      this.openFilter()
      this.getCategories()
    },
    methods: {
      editForm(id) {
        this.id = id
        setTimeout(this.openModal, 0)
      },
      openModal() {
        this.$bvModal.show('OrganizationEdit')
      },
      getCategories() {
        this.$http.get(`/organizations/org-types`)
        .then((res) => {
          this.typeOrganiza = res.data
        })
      },
      openFilter(){
        let allRoutes = this.$store.state.draggableTab.tabs
        let param = JSON.parse(localStorage.getItem(this.$route.name + 'data'))
        let arrayRoutes = []
        allRoutes.forEach(element => {
          arrayRoutes.push(element.path)
        });
        if (arrayRoutes.includes(this.$route.path) == true) {
          this.showPreloader = true
          this.$http
          .get(`${this.$route.name}`, {params: param}).then(res => {
            this.organization = res.data
            this.$store.commit('pageData/setdataCount', this.organization.length)
            this.showPreloader = false
            this.$store.commit('REFRESH_DATA', false)
          })
        }else if(arrayRoutes.includes(this.$route.path) == false){
          this.$bvModal.show(this.$route.name + 'filter')
        }
      },
      refresh(){
        this.showPreloader = true
        this.$http
        .get('/organizations')
        .then(res => {
          this.organization = res.data
          this.$store.commit('pageData/setdataCount', this.organization.length)
          this.showPreloader = false
          this.$store.commit('REFRESH_DATA', false)
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ошибка',
              icon: 'XIcon',
              variant: 'danger',
              text: err.response.data.errors,
            }
          })
        })
      },
      sendToParent(tableData){
        this.organization = tableData
        this.$store.commit('pageData/setdataCount', this.organization.length)
      },
    },
    computed: {
      fetchingNewData(){
        return this.$store.state.refresh.fetchingNewData
      }
    },
    watch: {
      fetchingNewData(val){
        let param = JSON.parse(localStorage.getItem(this.$route.name + "data"));
        if (val){
          this.$http
          .get(`${this.$route.name}`, {params: param})
          .then(res =>{
            this.organization = res.data
            this.$store.commit('pageData/setdataCount', this.organization.length)
            this.$store.commit('REFRESH_DATA', false)
          })
        }
      }
    }
  }
  </script>