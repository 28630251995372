<template>
    <div>
        <b-modal 
        id="OrganizationEdit" 
        title="Редактирование типа организация" 
        size="s"
        hide-footer no-enforce-focus 
        :no-close-on-backdrop="true"
        @show="created" 
        >
            <b-row class="mt-1">
                <b-col cols="12">
                    <label for="">Наименование</label>
                    <b-input v-model="organization.name" placeholder="Наименование" />
                </b-col>
            </b-row>
            <b-row class="mb-1">
            <b-col>
                    <label for="">Тип организации</label>
                    <v-select v-model="organization.org_type_id" :reduce="options => options.id"
                            placeholder="организация"
                            label="name"
                            :options="typeOrganiza">
                            <template #no-options>
                                <span>
                                    Данные отсутствуют!
                                </span>
                            </template>
                        </v-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                   <b-form-checkbox v-model="organization.is_active" class="custom-control-primary">
                    {{ organization.is_active ? 'Активный' : 'Неактивный' }}
                    </b-form-checkbox>  
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled || !organization.name"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="editBodyType"
                    >
                        Изменить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect,
        ToastificationContent,  

    },
    props: ['id'],
    data(){
        return {
            organization: {
                id: null,
                name: null,
                org_type_id: null,
                is_active: null,
            },
            submitButtonDisabled: false,
            typeOrganiza: [],
            
        }
    },
    mounted() {
        this.type()
    },
    methods: {
        closeModal() {
            this.$bvModal.hide('OrganizationEdit'),
            this.submitButtonDisabled = false,
            this.organization.name =null
        },
        created() {
        this.$http.get(`organizations/${this.id.id}/edit`)
        .then(res => {
            this.organization = res.data
            this.organization.is_active = res.data.is_active === 1 ? true : false
        })
    },
    type() {
        this.$http
        .get('organizations/org-types')
        .then(res => {
            this.typeOrganiza = res.data
        })
    },
        editBodyType() {
            this.submitButtonDisabled = true
            this.$http.patch(`/organizations/${this.organization.id}`, {
                name: this.organization.name,
                org_type_id: this.organization.org_type_id,
                is_active: this.organization.is_active,
            })
            .then(res => {
                this.$emit('refresh')
                this.closeModal()
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Типа организации изменена!',
                        icon: 'CheckIcon',
                        variant: 'success',
                        text: `${res.data.message}`
                    },
                })
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
    },
}
</script>