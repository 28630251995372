<template>
    <div>
        <b-modal :id="$route.name + 'CreateModal'" title="Добавить организацию" size="s" hide-footer no-enforce-focus :no-close-on-backdrop="true" @show="clearData">
            <b-row>
                <b-col cols="12">
                    <b-input v-model="organization.name" placeholder="Имя организация" />
                </b-col>
            </b-row>
            <b-row class="mb-1">
                <b-col cols="12" class="mt-1">
                    <label for="" disabled :value="null">Выберите тип организации</label>
                        <v-select v-model="organization.org_type_id" placeholder="организация"
                            :disabled="categoriesSelectDisabled"
                            :reduce="options => options.id"
                            label="name"
                            :options="typeOrganiza" >
                            <template #no-options>
                                <span>
                                    Данные отсутствуют!
                                </span>
                            </template>
                        </v-select>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" class="d-flex justify-content-between">
                    <b-button
                    :disabled="submitButtonDisabled"
                    class="mt-2 col-md-5"
                    variant="primary"
                    @click="createBodyType">
                        Добавить
                    </b-button>
                    <b-button class="mt-2 col-md-5" variant="secondary" @click="closeModal">
                        Отмена
                    </b-button>
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>
<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select';
export default {
    components: {
        ToastificationContent,
        vSelect,
    },
    props: ['typeOrganiza'],
    data(){
        return {
            organization: {
                name: null,
                org_type_id: null,
            },
            submitButtonDisabled: false,
            categoriesSelectDisabled: false,
        }
    },
    methods: {
        clearData(){
            this.organization = {
                name: null,
                org_type_id: null,
            }
        },
        closeModal() {
            this.$bvModal.hide(this.$route.name + 'CreateModal')
        },
        getOrgaization() {
            this.$http.get('/organizations/org-types')
            .then((res) => {
                this.typeOrganiza = res.data
            })
        },
        createBodyType() {
            this.submitButtonDisabled = true
            this.$http.post('organizations', this.organization)
            .then(() => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Тип организация создан!',
                        icon: 'CheckIcon',
                        variant: 'success',
                    },
                })
                this.$emit('refresh')
                this.clearData()
                this.closeModal()
            })
            .catch(err => {
                this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                        title: 'Некорректные данные!',
                        icon: 'XIcon',
                        variant: 'danger',
                        text: err.response.data.errors,
                    },
                })
            })
            .finally(() => {
                this.submitButtonDisabled = false
            })
        },
        
    }
}
</script>